<!--
 * @Author: 月魂
 * @Date: 2022-11-11 10:27:01
 * @LastEditTime: 2022-11-11 17:38:57
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\views\Taste\Food.vue
-->
<template>
  <div class="box">
    <div class="cont">
      <Navigation :title="food.title" />
      <img src="../../assets/temp/taste.png" alt="" class="img top-bg">
      <img src="../../assets/temp/taste-pc.png" alt="" class="img top-bg pc">
      <div class="poster">
        <img :src="domain + food.imgUrl" alt="" class="img">
      </div>
      <div class="des" v-for="(des, idx) in food.des" :key="idx">{{ des }}</div>
      
      <!-- 菜谱部分 -->
      <div class="part" v-if="food.title">
        <!-- 配料表 -->
        <div class="item">
          <div class="title">{{ food.list.name }}</div>
          <div class="des">{{ food.list.cont }}</div>
        </div>
        <!-- 制作步骤 -->
        <div class="item">
          <div class="title">{{ food.steps.name }}</div>
          <ol>
            <li class="des" v-for="(des, index) in food.steps.cont" :key="index">{{ des }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  name: 'TasteFood',
  components: {
    Navigation
  },
  data() {
    return {
      title: '',
      domain: '',
      id: 0,
      food: {}
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.food = this.$t('taste.menu')[this.id]
    this.domain = this.$t('domain')
    this.title = this.$t('taste.title')
    this.wxShare(this.$t('title'), this.$t('taste.title'))
  },
  watch: {
    '$i18n.locale'() {
      this.food = this.$t('taste.menu')[this.id]
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #06062B;
  position: relative;
  padding-bottom: 20px;
  color: #fff;
  .cont {
    max-width: 1200PX;
    margin: auto;
  }
  .top-bg {
    position: absolute;
    top: 0;
    left: 0;
  }
  .pc {
    display: none;
  }
  @media (min-width: 1000PX) {
    .top-bg {
      display: none;
    }
    .pc {
      display: block;
    }
  }
  .poster {
    width: 100%;
    padding: 0 16px;
    position: relative;
    z-index: 1;
    .img {
      display: block;
      width: 100%;
      border-radius: 15px;
    }
  }
  .des {
    width: 100%;
    padding: 0 16px;
    font-size: 14px;
    color: #999;
    line-height: 30px;
    margin-top: 8px;
  }
  .part {
    padding: 12px 16px;
    max-width: 1200PX;
    margin: auto;
    margin-top: 8px;
    .item {
      width: 100%;
      border-radius: 15px;
      background: #111135;
      overflow: hidden;
      padding: 12px 16px 8px;
      margin-bottom: 16px;
      .title {
        font-size: 16px;
        color: #fff;
        line-height: 24px;
      }
      .des {
        padding: 0;
      }
    }
  }
}
</style>
